import logo from "./logo.svg";
import "./App.css";
import "aos/dist/aos.css";
import mobileLanding from "./assets/mobileLanding.png";
import NavbarDesktop from "./components/NavbarDesktop";

import Footer from "./components/Footer";
import chatFloating from "./assets/chatFloating.png";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import React, { useEffect, useState } from "react";
import Home from "./pages/Home";
import PageIndex from "./pages/PageIndex";
// import Logistik from "./pages/Logistik";
// import Whistleblower from "./pages/Whistleblower";
import SejarahSingkat from "./pages/Profil/SejarahSingkat";
import VisiMisi from "./pages/Profil/VisiMisi";
import BisnisPerusahaan from "./pages/Profil/BisnisPerusahaan";
import TujuanDanBudaya from "./pages/Profil/TujuanDanBudaya";
import Manajemen from "./pages/Profil/Manajemen";
import GCG from "./pages/Profil/GCG";
import PKBL from "./pages/Profil/PKBL";
import Laporan from "./pages/Profil/Laporan";
import InformasiPemegangSaham from "./pages/Profil/InformasiPemegangSaham";
import Komite from "./pages/Profil/Komite";
import RKAP from "./pages/Profil/RKAP";
import KatalogById from "./pages/Katalog/KatalogById";
import ComingSoon from "./pages/ComingSoon";

import Katalog from "./pages/Katalog/Katalog";

import Logistik from "./pages/Produk/Logistik";

import FasilitasUmum from "./pages/Produk/FasilitasUmum";
import KBNBeton from "./pages/Produk/KBNBeton";
import PLB from "./pages/Produk/PLB";
import Properti from "./pages/Produk/Properti";
import PusatBisnis from "./pages/Produk/PusatBisnis";
import RSU from "./pages/Produk/RSU";
import SBUPengelolaanAir from "./pages/Produk/SBUPengelolaanAir";

import Whistleblower from "./pages/Whistleblower";
import PPID from "./pages/SuaraPelanggan/PPID";
import PetunjukInvestor from "./pages/SuaraPelanggan/PetunjukInvestor";
import Pengaduan from "./pages/SuaraPelanggan/Pengaduan";
import FAQ from "./pages/SuaraPelanggan/FAQ";

import Foto from "./pages/Media/Foto";
import Majalah from "./pages/Media/Majalah";

import Video from "./pages/Media/Video";
import Pengumuman from "./pages/Media/Pengumuman";

import Penghargaan from "./pages/Media/Penghargaan";
import RUPS from "./pages/Media/RUPS";
import Kliping from "./pages/Media/Kliping";
import KlipingById from "./pages/Media/KlipingById.js";
import Berita from "./pages/Media/Berita.js";
import BeritaById from "./pages/Media/BeritaById.js";
import KalenderKegiatan from "./pages/Media/KalenderKegiatan";
import KinerjaKeuangan from "./pages/Media/KinerjaKeuangan";

import Login from "./pages/Whistleblower/Login";
import Register from "./pages/Whistleblower/Register";
import Form from "./pages/Whistleblower/Form";
import Status from "./pages/Whistleblower/Status";

// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import { Autoplay } from "swiper";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from "react-router-dom";
import { server } from "./config/server.js";
function App() {

  const [width, setWidth] = useState(0);
  const [swiper, setSwiper] = useState(<Swiper />);
  const [lang, setLang] = useState(false);

  const [ws, setWs] = useState(null);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "id",
        autoDisplay: true,
        defaultLanguage: "en",
        includedLanguages: "en",
      },
      "google_translate_element"
    );
  };


  const translateOn = () => {
    return new Promise(async (resolve) => {
      document.cookie = "googtrans=/en";
      await addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      await document.body.appendChild(addScript);
      window.googleTranslateElementInit = await googleTranslateElementInit;
      await resolve("ok");
    });
  };

  var addScript = document.createElement("script");

  useEffect(() => {

    const webSocket = new WebSocket(server);
    setWs(webSocket);

    // WebSocket Event Handlers
    webSocket.onopen = () => {
      console.log("WebSocket connection established");
      webSocket.send(JSON.stringify({ type: "hello", message: "Hello server!" }));
    };

    webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Message received from server:", message);
    };

    webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    webSocket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Cleanup on unmount
    return () => {
      webSocket.close();
    };

  }, []);

  const handleLanguage = async (lang) => {
    console.log(googleTranslateElementInit, "broxs", lang);

    // window.googleTranslateElementInit = await googleTranslateElementInit;
    setLang(lang);


    // window.googleTranslateElementInit = googleTranslateElementInit;
    switch (lang) {
      case "en":
        await translateOn().then((res) => {
          console.log(res);
          console.log(document.getElementsByClassName("goog-te-combo").length);
        });
        break;
      case "id":
        window.location.reload();
        // window.googleTranslateElementInit = googleTranslateElementInit2;
        break;
      default:
        break;
    }
  };
  return (
    <div className="homePageContainer">
      <div id="google_translate_element"></div>
      <div class="homePageWrapper">
        <Router>

          <NavbarDesktop handleLanguage={handleLanguage} />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="produk/*" Component={PageIndex}>
              {/* <Route index Component={InvoiceList} /> */}
              <Route path="logistik" Component={Logistik} />
              <Route path="fasilitas-umum" Component={FasilitasUmum} />
              <Route path="kbn-beton" Component={KBNBeton} />
              <Route path="plb" Component={PLB} />
              <Route path="properti" Component={Properti} />
              <Route path="pusat-bisnis" Component={PusatBisnis} />
              <Route path="sbu-pengelolaan-air" Component={SBUPengelolaanAir} />
              <Route path="rsu" Component={RSU} />
            </Route>
            <Route path="whistleblower/*" Component={PageIndex}>
              {/* <Route index Component={InvoiceList} /> */}
              <Route path="" Component={Whistleblower} />
              <Route path="login" Component={Login} />
              <Route path="register" Component={Register} />
              <Route path="form" Component={Form} />
              <Route path="status" Component={Status} />
            </Route>
            <Route path="katalog/" element={Katalog} Component={PageIndex}>
              <Route path="" Component={Katalog} />
              {/* <Route index Component={InvoiceList} /> */}
              <Route path=":id" Component={KatalogById} />
            </Route>
            <Route path="profil/*" Component={PageIndex}>
              {/* <Route index Component={InvoiceList} /> */}
              <Route path="sejarah-singkat" Component={SejarahSingkat} />
              <Route path="visi-misi" Component={VisiMisi} />
              <Route path="bisnis-perusahaan" Component={BisnisPerusahaan} />
              <Route path="tujuan-dan-budaya" Component={TujuanDanBudaya} />
              <Route path="manajemen" Component={Manajemen} />
              <Route path="gcg" Component={GCG} />
              <Route path="pkbl" Component={PKBL} />
              <Route path="laporan" Component={Laporan} />
              <Route
                path="informasi-pemegang-saham"
                Component={InformasiPemegangSaham}
              />
              <Route path="komite" Component={Komite} />
              <Route path="rkap" Component={RKAP} />
            </Route>
            <Route path="suara-pelanggan/*" Component={PageIndex}>
              {/* <Route index Component={InvoiceList} /> */}
              <Route path="whistleblower" Component={Whistleblower} />
              <Route path="ppid" Component={PPID} />
              <Route path="petunjuk-investor" Component={PetunjukInvestor} />
              <Route path="pengaduan" Component={Pengaduan} />
              <Route path="faq" Component={FAQ} />
              {/* <Route path=":id" Component={KatalogById} /> */}
            </Route>
            <Route path="media/*" Component={PageIndex}>
              {/* <Route index Component={InvoiceList} /> */}
              <Route path="foto" Component={Foto} />
              <Route path="majalah" Component={Majalah} />
              <Route path="video" Component={Video} />
              <Route path="penghargaan" Component={Penghargaan} />
              <Route path="pengumuman" Component={Pengumuman} />
              <Route path="rups" Component={RUPS} />
              <Route path="kinerja-keuangan" Component={KinerjaKeuangan} />
              <Route path="kalender-kegiatan" Component={KalenderKegiatan} />

              <Route path="berita/">
                <Route path=":id" Component={BeritaById} />
                <Route path="" Component={Berita} />
              </Route>
              <Route path="kliping/">
                <Route path=":id" Component={KlipingById} />
                <Route path="" Component={Kliping} />
              </Route>
            </Route>
            <Route path="/comingsoon" element={<ComingSoon />} />
            {/* 
          
          <Route path="payment/*" Component={PaymentIndex}>
            <Route index Component={SalesList} />
            <Route path="master" Component={MasterPayment} />
          </Route>
          <Route path="bod/*" Component={BODIndex}>
            <Route index Component={BODList} />
          </Route> */}

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
          <Footer />
          {/* <div
            class="floatingChat pointer"
            style={{
              position: "fixed",

              bottom: "13%",
              marginLeft: "93%",
            }}
          >
            <img src={chatFloating} />
          </div> */}
        </Router>
      </div>
    </div>
  );
}

export default App;
