import logoKbn from "../assets/logoKbn.png";
import kbumn_ind from "../assets/kbumn_ind.png";
import danareksa from "../assets/danareksa.png";
import jayaraya from "../assets/jayaraya.png";

import homeSmall from "../assets/homeSmall.png";
import glass from "../assets/glass.png";
import langId from "../assets/langId.png";
import langEn from "../assets/langEn.png";
import arrowMenu from "../assets/arrowMenu.png";
import burgerMenu from "../assets/burgerMenu.png";
import { useNavigate } from "react-router-dom";
import { server } from "../config/server";
import React, { useEffect, useState } from "react";


export default function NavbarDesktop({ handleLanguage }) {



  const handleMobileNavigation = (target) => {
    document.getElementById("mobileMenu").classList.remove("mobileMenuActive");
    setNavbarMobileVisibility(false);
    navigate(target);
  };
  const switchLanguage = (i) => {
    handleLanguage(i);
  };
  const [offset, setOffset] = useState(0);
  const [navbarMobileVisibility, setNavbarMobileVisibility] = useState(false);
  const [mobileSubmenu, setMobileSubmenu] = useState(false);
  const navigate = useNavigate();
  const [menuData, setMenuData] = useState([]);
  const [menuHeaderData, setMenuHeaderData] = useState([]);


  useEffect(() => {
    if (1) {
      if (offset > 0) {
        document.getElementById("navbarTop").style.opacity = 0;
        document.getElementById("navbarBottom").style.boxShadow =
          "0px 4px 14px rgb(0 0 0 / 7%)";
        document.getElementById("navbarBottom").style.paddingTop = '10px'
      }
      if (offset == 0) {
        document.getElementById("navbarTop").style.opacity = 1
        document.getElementById("navbarBottom").style.boxShadow = "none";
        document.getElementById("navbarBottom").style.paddingTop = '93px'
      }
    }



    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [offset]);

  useEffect(() => {

    fetch(`${server}/api/v1/content/menu`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // Add Authorization header if needed
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setMenuData(res.data);
        }
      })
      .catch((err) => {
        console.error("Error fetching menu data:", err);
      });
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {

    fetch(`${server}/api/v1/content/menuHeader`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setMenuHeaderData(res.data);
        }
      })
      .catch((err) => {
        console.error("Error fetching menu header data:", err);
      });
  }, []); // Empty dependency array ensures this runs once on mount


  const navbarMobileHandle = () => {
    const submenu = document.getElementsByClassName("mobileSubmenuWrapper");
    console.log(submenu);
    // if (!navbarMobileVisibility) {
    //   document.getElementById("mobileMenu").classList.add("mobileMenuActive");
    //   setNavbarMobileVisibility(true);
    // } else {
    //   for (let i = 0; i < submenu.length; i++) {
    //     const element = submenu[i];
    //     console.log(element.classList);
    //     const element_class_list = element.classList;
    //     for (let j = 0; j < element_class_list.length; j++) {
    //       const element_class = element_class_list[j];
    //       if (element_class == "mobileSubmenuWrapperActive") {
    //         element.classList.remove("mobileSubmenuWrapperActive");
    //       }
    //     }
    //   }

    // }
    if (!navbarMobileVisibility) {
      document.getElementById("mobileMenu").classList.add("mobileMenuActive");
      setNavbarMobileVisibility(false);
    } else {
      document
        .getElementById("mobileMenu")
        .classList.remove("mobileMenuActive");
      setNavbarMobileVisibility(false);
    }
    setNavbarMobileVisibility(!navbarMobileVisibility);
  };

  const handleMobileSubmenu = (submenu) => {
    console.log(submenu, "as");
    // if (submenu == mobileSubmenu) {
    //   document
    //     .getElementById(submenu)
    //     .classList.remove("mobileSubmenuWrapperActive");
    // }

    let all_submenu = document.getElementsByClassName("mobileSubmenuWrapper");
    for (let i = 0; i < all_submenu.length; i++) {
      const element = all_submenu[i];
      console.log(element);
      if (element.id == submenu) {
        const y = element.classList;
        let flag = true;
        for (let j = 0; j < y.length; j++) {
          const element_class = y[j];
          console.log(element_class);
          if (element_class == "mobileSubmenuWrapperActive") {
            element.classList.remove("mobileSubmenuWrapperActive");
            flag = false;
          }
        }
        if (flag) {
          element.classList.add("mobileSubmenuWrapperActive");
        }
      } else {
        element.classList.remove("mobileSubmenuWrapperActive");
      }
    }

    // console.log(all_submenu);
    let flag = false;

    setMobileSubmenu(submenu);
  };



  return (
    <div
      id="navbarMain"
      // class="position-sticky"
      style={{
        // position: "sticky",
        whiteSpace: "nowrap",
        top: 0,

        backgroundColor: "#f5f5f5",
      }}
    >
      <div id="navbarTop" class="navbarContainer upperHomeContainer mx-auto">
        <div
          class="fe1200"
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",

            display: "inline-flex",
          }}
        >
          <div class="flex none1200 fe1200" style={{ gap: 20, maxHeight: 200 }}>
            <div class="center-vertical pointer mh100">
              <img
                onClick={() => window.open("https://bumn.go.id", "_blank")}
                src={kbumn_ind}
                class="kbumn"
                style={{ height: 45 }}
              ></img>
            </div>
            <div class="center-vertical pointer mh100">
              <img
                onClick={() => window.open("https://danareksa.co.id", "_blank")}
                src={danareksa}
                class="danareksa"
                style={{ height: 35 }}
              ></img>
            </div>
            <div class="center-vertical pointer mh100">
              <img
                onClick={() => window.open("https://jakarta.go.id", "_blank")}
                src={jayaraya}
                class="dki"
                style={{ height: 40 }}
              ></img>
            </div>
          </div>
          <div
            class="topMenu"
            style={{
              justifyContent: "space-around",
              alignItems: "flex-start",
              // gap: 56,
              width: '100%',
              display: "flex",
            }}
          >
            <div
              class="pointer hover-underline navbarFont upFont"
              style={{
                color: "#005F3B",

                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              <div
                class="center-vertical phoneNavbar"
                style={{
                  justifyContent: "center",
                  alignItems: "flex-end",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    color: "#005F3B",

                    fontFamily: "Arial",
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="pointer"
                >
                  <a class="text-none navbarFont upFont" href="tel:02144820909">
                    Hotline Service :
                  </a>
                </div>
                <div
                  class="center-vertical "
                  style={
                    {
                      // border: "1.50px #005F3B solid",
                    }
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="21"
                    viewBox="0 0 16 21"
                    fill="none"
                  >
                    <path
                      d="M8.24947 5.49405L6.52016 1.45844C6.32065 0.992857 5.74128 0.810346 5.25696 1.06053L2.28386 2.59634C1.39902 3.05342 0.888611 3.98905 1.02066 4.91195C1.81597 10.4707 4.42781 15.5434 8.48832 19.4154C9.16251 20.0583 10.2196 20.1842 11.1043 19.7272L14.1057 18.1767C14.592 17.9256 14.7786 17.3437 14.5119 16.9109L12.208 13.1721C11.9669 12.7808 11.4395 12.6535 11 12.8806L9.54254 13.6335C9.38042 13.7172 9.18642 13.6876 9.07189 13.5618C7.59681 11.9415 6.58317 9.97295 6.12045 7.82972C6.08452 7.66329 6.17299 7.48781 6.33505 7.40409L7.78811 6.65348C8.22948 6.42549 8.43131 5.91836 8.24947 5.49405Z"
                      stroke="#005F3B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    color: "#005F3B",

                    fontFamily: "Arial",
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="pointer"
                >
                  <a class="text-none navbarFont upFont" href="tel:02144820909">
                    (021) 4482-0909
                  </a>
                </div>
              </div>
            </div>

            {/* MENU HEADER */}

            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", alignItems: "center" }}>
              {menuHeaderData.map((menu) => (
                <div
                  key={menu.menu_id}
                  className="pointer hover-underline navbarFont center-vertical upFont "
                  style={{
                    color: "#005F3B",
                    fontFamily: "Roboto",
                    fontWeight: "300",
                    fontSize: "20px",  // Adjust font size here
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (menu.url.startsWith("http")) {
                      // Open external links in a new tab
                      window.open(menu.url, "_blank");
                    } else {
                      // Navigate to internal routes
                      navigate(menu.url);
                    }
                  }}
                >
                  <div>{menu.menu_name}</div>
                </div>
              ))}
            </div>

            <div
              class="flex center-vertical dropdownMenu dropdownHover pointer upFont"
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              <div
                class="flex"
                onClick={(e) => {
                  switchLanguage("id");
                }}
              >
                <img
                  class="center-vertical"
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: "1.2rem",
                    marginLeft: "1rem",
                  }}
                  src={langId}
                ></img>
                <div>Bahasa</div>
              </div>
              <div class="dropdownDialog" style={{ boxShadow: "none" }}>
                <div
                  class="dropdownSubmenu xx pointer navbarFont upFont flex"
                  // onClick={(e) => {
                  //   navigate("/profil/sejarah-singkat");
                  // }}
                  style={{
                    paddingTop: "0.45rem",
                    paddingLeft: 0,
                    borderBottom: "0px",
                    paddingRight: "1rem",
                  }}
                >
                  <div
                    class="flex center-vertical dropdownMenu dropdownHover upFont"
                    style={{
                      color: "#005F3B",
                      fontSize: 18,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                    onClick={(e) => {
                      switchLanguage("en");
                    }}
                  >
                    <img
                      class="center-vertical"
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: "1.2rem",
                        marginLeft: "1rem",
                      }}
                      src={langEn}
                    ></img>
                    <div>English</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navbarWrapper"></div>
      </div>
      <div class='w100 bottomNavbar fixed-top' style={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        // marginTop: 93,
        backgroundColor: "#f5f5f5",
      }}
        id='navbarBottom'>
        <div
          class="upperHomeContainer mx-auto "
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            // marginTop: 93,
            backgroundColor: "#f5f5f5",
          }}

        >
          <img
            class="pointer"
            onClick={(e) => {
              window.scrollTo(0, 0);
              navigate("/");
            }}
            style={{ width: 105, height: 83 }}
            src={logoKbn}
          />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
              display: "flex",
            }}
          >
            <div
              class="center-vertical pointer"
              onClick={(e) => { window.scrollTo(0, 0); navigate("/") }}
              style={{ width: 20, height: 20 }}
            >
              <img src={homeSmall}></img>
            </div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
                display: "flex",
              }}
            >

              {/* MENU DETAIL*/}

              {menuData.map((menu) => (
                <div
                  key={menu.menu_id}
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                    display: "flex",
                  }}
                  className="dropdownMenu pointer hover-text-shadow"
                >
                  {/* Main Menu Item */}
                  <div
                    className="center-vertical navbarFont dropdownHover"
                    style={{
                      color: "#005F3B",
                      fontSize: 18,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                      wordWrap: "break-word",
                    }}
                  >
                    {menu.menu_name}
                  </div>

                  {/* Submenu */}
                  {menu.subMenu && menu.subMenu.length > 0 && (
                    <div className="dropdownDialog">
                      {menu.subMenu.map((subMenu) => (
                        <div
                          key={subMenu.menu_id}
                          className="dropdownSubmenu pointer navbarFont"
                          onClick={() => navigate(subMenu.url)}
                        >
                          {subMenu.menu_name}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Arrow Icon */}
                  <div
                    className="center-vertical"
                    style={{
                      width: 10,
                      height: 15,
                      cursor: "pointer",
                    }}
                  >
                    <img src={arrowMenu} alt="Arrow Icon" />
                  </div>
                </div>
              ))}



            </div>
            <div class="flex center-vertical searchHover" style={{ gap: 20 }}>
              <div class="center-vertical" style={{ width: 20, height: 20 }}>
                <img class="w100" src={glass}></img>
              </div>
              <input type="text" class="searchNav"></input>
            </div>
          </div>
        </div>
      </div>

      <div
        id="nav-mobile"
        class="mobileNavbar flex upperHomeContainer mx-auto "
        style={{ justifyContent: "space-between" }}
      >
        <div class="mt-4">
          <img
            onClick={(e) => {
              handleMobileNavigation("/");
            }}
            style={{ width: 105, height: 83 }}
            class="pointer"
            src={logoKbn}
          />
        </div>
        <div>
          <div
            class="pointer"
            style={{
              color: "#005F3B",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: "300",
              wordWrap: "break-word",
            }}
          >
            <a class="text-none navbarFont" href="tel:02144820909">
              (021) 4482-0909
            </a>
          </div>

          <div class="w100">
            <img
              class="w100 center-vertical burgerMenu"
              style={{ marginLeft: "auto" }}
              onClick={(e) => {
                navbarMobileHandle(e);
              }}
              src={burgerMenu}
            ></img>
          </div>
        </div>
      </div>
      <div
        id="mobileMenu"
        style={{
          position: "absolute",

          width: "100vw",
          backgroundColor: "white",
          zIndex: 99,

          paddingTop: "2rem",
        }}
      >
        <div>
          <div
            onClick={(e) => {
              handleMobileSubmenu("mobile_profil");
            }}
            class="pointer hover-underline my-3 mx-4"
            style={{
              color: "#005F3B",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            Profil Perusahaan
          </div>


          <div class="mx-5 mobileSubmenuWrapper" id="mobile_profil">
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/sejarah-singkat");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Sejarah Singkat
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/visi-misi");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Visi dan Misi
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/bisnis-perusahaan");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Bisnis Perusahaan
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/tujuan-dan-budaya");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Tujuan dan Budaya
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/manajemen");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Manajemen
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/gcg");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              GCG
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/pkbl");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              PKBL
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/laporan");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Laporan
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/informasi-pemegang-saham");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Informasi Pemegang Saham
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/profil/komite");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Komite
            </div>
            {/* <div
              onClick={(e) => {
                handleMobileNavigation("/profil/rkap");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              RKAP
            </div> */}
          </div>
        </div>
        <div>
          {" "}
          <div
            onClick={(e) => {
              handleMobileSubmenu("mobile_produk");
            }}
            class="pointer hover-underline my-3 mx-4"
            style={{
              color: "#005F3B",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            Produk
          </div>
          <div class="mx-5 mobileSubmenuWrapper" id="mobile_produk">
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/properti");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Properti
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Logistik
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/plb");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Pusat Logistik Berikat
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/kbn-beton");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              KBN Beton
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/rsu");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              RSU & Pekerja
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/pusat-bisnis");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Pusat Bisnis
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/sbu-pengelolaan-air");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              SBU Pengelolaan Air
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/fasilitas-umum");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Fasilitas Umum
            </div>
          </div>
        </div>

        <div>
          {" "}
          <div
            onClick={(e) => {
              console.log("submenu");
              handleMobileNavigation("/katalog");
            }}
            class="pointer hover-underline my-3 mx-4"
            style={{
              color: "#005F3B",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            Katalog Produk
          </div>
          <div class="mx-5 mobileSubmenuWrapper">
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Properti
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Logistik
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Pusat Logistik Berikat
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Tujuan dan Budaya
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              GCG
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              PKBL
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Laporan
            </div>
          </div>
        </div>

        <div>
          {" "}
          <div
            onClick={(e) => {
              console.log("submenu");
              handleMobileSubmenu("mobile_media");
            }}
            class="pointer hover-underline my-3 mx-4"
            style={{
              color: "#005F3B",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            Media
          </div>
          <div class="mx-5 mobileSubmenuWrapper" id="mobile_media">
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/berita");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Berita
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/foto");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Logistik
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/video");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Video
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/majalah");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Majalah
            </div>
            {/* <div
              onClick={(e) => {
                handleMobileNavigation("/media/kliping");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
             Kliping
            </div> */}
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/pengumuman");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Pengumuman
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/penghargaan");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Penghargaan
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/rups");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Informasi RUPS
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/kinerja-keuangan");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Kinerja Keuangan
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/media/kalender-kegiatan");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Kalender Kegiatan
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div
            onClick={(e) => {
              console.log("submenu");
              handleMobileNavigation("/media/pengumuman");
            }}
            class="pointer hover-underline my-3 mx-4"
            style={{
              color: "#005F3B",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            Pengadaan Barang dan Jasa
          </div>
          <div class="mx-5 mobileSubmenuWrapper">
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Properti
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Logistik
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Pusat Logistik Berikat
            </div>{" "}
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Tujuan dan Budaya
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              GCG
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              PKBL
            </div>
            <div
              onClick={(e) => {
                handleMobileNavigation("/produk/logistik");
              }}
              class="pointer hover-underline navbarFont "
              style={{
                color: "#005F3B",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              Laporan
            </div>
          </div>
        </div>
        <div style={{ height: "100vh" }}></div>
      </div>
    </div>
  );

  //   return (
  //     <div
  //       id="navbarMain"
  //       // class="position-sticky"
  //       style={{
  //         // position: "sticky",
  //         whiteSpace: "nowrap",
  //         top: 0,

  //         backgroundColor: "#f5f5f5",
  //       }}
  //     >
  //       <div id="navbarTop" class="navbarContainer upperHomeContainer mx-auto">
  //         <div
  //           class="fe1200"
  //           style={{
  //             width: "100%",
  //             height: "100%",
  //             justifyContent: "space-between",
  //             alignItems: "center",

  //             display: "inline-flex",
  //           }}
  //         >
  //           <div class="flex none1200 fe1200" style={{ gap: 20, maxHeight: 200 }}>
  //             <div class="center-vertical pointer mh100">
  //               <img
  //                 onClick={() => window.open("https://bumn.go.id", "_blank")}
  //                 src={kbumn_ind}
  //                 class="kbumn"
  //                 style={{ height: 45 }}
  //               ></img>
  //             </div>
  //             <div class="center-vertical pointer mh100">
  //               <img
  //                 onClick={() => window.open("https://danareksa.co.id", "_blank")}
  //                 src={danareksa}
  //                 class="danareksa"
  //                 style={{ height: 35 }}
  //               ></img>
  //             </div>
  //             <div class="center-vertical pointer mh100">
  //               <img
  //                 onClick={() => window.open("https://jakarta.go.id", "_blank")}
  //                 src={jayaraya}
  //                 class="dki"
  //                 style={{ height: 40 }}
  //               ></img>
  //             </div>
  //           </div>
  //           <div
  //             class="topMenu"
  //             style={{
  //               justifyContent: "space-around",
  //               alignItems: "flex-start",
  //               // gap: 56,
  //               width: '100%',
  //               display: "flex",
  //             }}
  //           >
  //             <div
  //               class="pointer hover-underline navbarFont upFont"
  //               style={{
  //                 color: "#005F3B",

  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               <div
  //                 class="center-vertical phoneNavbar"
  //                 style={{
  //                   justifyContent: "center",
  //                   alignItems: "flex-end",
  //                   gap: 6,
  //                   display: "flex",
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     color: "#005F3B",

  //                     fontFamily: "Arial",
  //                     fontWeight: "400",

  //                     wordWrap: "break-word",
  //                   }}
  //                   class="pointer"
  //                 >
  //                   <a class="text-none navbarFont upFont" href="tel:02144820909">
  //                     Hotline Service :
  //                   </a>
  //                 </div>
  //                 <div
  //                   class="center-vertical "
  //                   style={
  //                     {
  //                       // border: "1.50px #005F3B solid",
  //                     }
  //                   }
  //                 >
  //                   <svg
  //                     xmlns="http://www.w3.org/2000/svg"
  //                     width="16"
  //                     height="21"
  //                     viewBox="0 0 16 21"
  //                     fill="none"
  //                   >
  //                     <path
  //                       d="M8.24947 5.49405L6.52016 1.45844C6.32065 0.992857 5.74128 0.810346 5.25696 1.06053L2.28386 2.59634C1.39902 3.05342 0.888611 3.98905 1.02066 4.91195C1.81597 10.4707 4.42781 15.5434 8.48832 19.4154C9.16251 20.0583 10.2196 20.1842 11.1043 19.7272L14.1057 18.1767C14.592 17.9256 14.7786 17.3437 14.5119 16.9109L12.208 13.1721C11.9669 12.7808 11.4395 12.6535 11 12.8806L9.54254 13.6335C9.38042 13.7172 9.18642 13.6876 9.07189 13.5618C7.59681 11.9415 6.58317 9.97295 6.12045 7.82972C6.08452 7.66329 6.17299 7.48781 6.33505 7.40409L7.78811 6.65348C8.22948 6.42549 8.43131 5.91836 8.24947 5.49405Z"
  //                       stroke="#005F3B"
  //                       stroke-width="1.5"
  //                       stroke-linecap="round"
  //                       stroke-linejoin="round"
  //                     />
  //                   </svg>
  //                 </div>
  //                 <div
  //                   style={{
  //                     color: "#005F3B",

  //                     fontFamily: "Arial",
  //                     fontWeight: "400",

  //                     wordWrap: "break-word",
  //                   }}
  //                   class="pointer"
  //                 >
  //                   <a class="text-none navbarFont upFont" href="tel:02144820909">
  //                     (021) 4482-0909
  //                   </a>
  //                 </div>
  //               </div>
  //             </div>

  //             <div
  //               class="pointer hover-underline navbarFont center-vertical upFont  "
  //               style={{
  //                 color: "#005F3B",
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //               onClick={() => navigate("/whistleblower")}
  //             >
  //               <div>
  //                 WBS
  //               </div>

  //             </div>
  //             <div
  //               class="pointer hover-underline navbarFont center-vertical upFont  "
  //               style={{
  //                 color: "#005F3B",
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //               onClick={() =>
  //                 window.open("https://crm.kbn.co.id/crm_kbn/", "_blank")
  //               }
  //             >
  //               CRM
  //             </div>
  //             <div
  //               class="pointer hover-underline navbarFont center-vertical upFont  "
  //               style={{
  //                 color: "#005F3B",
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //               onClick={() =>
  //                 window.open("https://eproc.kbn.co.id/", "_blank")
  //               }

  //             >
  //               E PROC
  //             </div>
  //             <div
  //               class="pointer hover-underline navbarFont center-vertical upFont  "
  //               style={{
  //                 color: "#005F3B",
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //               onClick={(e) => {
  //                 navigate("/suara-pelanggan/ppid");
  //               }}
  //             >
  //               PPID
  //             </div>
  //             <div
  //               class="pointer hover-underline navbarFont upFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //               onClick={() =>
  //                 window.open("https://ecommerce.kbn.co.id", "_blank")
  //               }
  //             >
  //               E COMM
  //             </div>
  //             <div
  //               class="pointer hover-underline navbarFont upFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //               onClick={() =>
  //                 window.open("https://csirt.kbn.co.id/", "_blank")
  //               }
  //             >
  //               CSIRT
  //             </div>
  //             <div
  //               class="flex center-vertical dropdownMenu dropdownHover pointer upFont"
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               <div
  //                 class="flex"
  //                 onClick={(e) => {
  //                   switchLanguage("id");
  //                 }}
  //               >
  //                 <img
  //                   class="center-vertical"
  //                   style={{
  //                     width: 20,
  //                     height: 20,
  //                     marginRight: "1.2rem",
  //                     marginLeft: "1rem",
  //                   }}
  //                   src={langId}
  //                 ></img>
  //                 <div>Bahasa</div>
  //               </div>
  //               <div class="dropdownDialog" style={{ boxShadow: "none" }}>
  //                 <div
  //                   class="dropdownSubmenu xx pointer navbarFont upFont flex"
  //                   // onClick={(e) => {
  //                   //   navigate("/profil/sejarah-singkat");
  //                   // }}
  //                   style={{
  //                     paddingTop: "0.45rem",
  //                     paddingLeft: 0,
  //                     borderBottom: "0px",
  //                     paddingRight: "1rem",
  //                   }}
  //                 >
  //                   <div
  //                     class="flex center-vertical dropdownMenu dropdownHover upFont"
  //                     style={{
  //                       color: "#005F3B",
  //                       fontSize: 18,
  //                       fontFamily: "Roboto",
  //                       fontWeight: "300",
  //                     }}
  //                     onClick={(e) => {
  //                       switchLanguage("en");
  //                     }}
  //                   >
  //                     <img
  //                       class="center-vertical"
  //                       style={{
  //                         width: 20,
  //                         height: 20,
  //                         marginRight: "1.2rem",
  //                         marginLeft: "1rem",
  //                       }}
  //                       src={langEn}
  //                     ></img>
  //                     <div>English</div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div class="navbarWrapper"></div>
  //       </div>
  //       <div class='w100 bottomNavbar fixed-top' style={{
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //         display: "flex",
  //         // marginTop: 93,
  //         backgroundColor: "#f5f5f5",
  //       }}
  //         id='navbarBottom'>
  //         <div
  //           class="upperHomeContainer mx-auto "
  //           style={{
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //             display: "flex",
  //             // marginTop: 93,
  //             backgroundColor: "#f5f5f5",
  //           }}

  //         >
  //           <img
  //             class="pointer"
  //             onClick={(e) => {
  //               window.scrollTo(0, 0);
  //               navigate("/");
  //             }}
  //             style={{ width: 105, height: 83 }}
  //             src={logoKbn}
  //           />
  //           <div
  //             style={{
  //               justifyContent: "center",
  //               alignItems: "center",
  //               gap: 20,
  //               display: "flex",
  //             }}
  //           >
  //             <div
  //               class="center-vertical pointer"
  //               onClick={(e) => { window.scrollTo(0, 0); navigate("/") }}
  //               style={{ width: 20, height: 20 }}
  //             >
  //               <img src={homeSmall}></img>
  //             </div>
  //             <div
  //               style={{
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 gap: 20,
  //                 display: "flex",
  //               }}
  //             >
  // {/* 
  //               <div style={{ display: "flex", gap: 20 }}>
  //                 {menuData.map((menuItem) => (
  //                   MenuItem(menuItem.menu_id,)  
  //                   // <MenuItem key={menuItem.menu_id} item={menuItem} navigate={navigate} />
  //                 ))}
  //               </div> */}




  //             </div>
  //             <div class="flex center-vertical searchHover" style={{ gap: 20 }}>
  //               <div class="center-vertical" style={{ width: 20, height: 20 }}>
  //                 <img class="w100" src={glass}></img>
  //               </div>
  //               <input type="text" class="searchNav"></input>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div
  //         id="nav-mobile"
  //         class="mobileNavbar flex upperHomeContainer mx-auto "
  //         style={{ justifyContent: "space-between" }}
  //       >
  //         <div class="mt-4">
  //           <img
  //             onClick={(e) => {
  //               handleMobileNavigation("/");
  //             }}
  //             style={{ width: 105, height: 83 }}
  //             class="pointer"
  //             src={logoKbn}
  //           />
  //         </div>
  //         <div>
  //           <div
  //             class="pointer"
  //             style={{
  //               color: "#005F3B",
  //               fontSize: 18,
  //               fontFamily: "Roboto",
  //               fontWeight: "300",
  //               wordWrap: "break-word",
  //             }}
  //           >
  //             <a class="text-none navbarFont" href="tel:02144820909">
  //               (021) 4482-0909
  //             </a>
  //           </div>

  //           <div class="w100">
  //             <img
  //               class="w100 center-vertical burgerMenu"
  //               style={{ marginLeft: "auto" }}
  //               onClick={(e) => {
  //                 navbarMobileHandle(e);
  //               }}
  //               src={burgerMenu}
  //             ></img>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="mobileMenu"
  //         style={{
  //           position: "absolute",

  //           width: "100vw",
  //           backgroundColor: "white",
  //           zIndex: 99,

  //           paddingTop: "2rem",
  //         }}
  //       >
  //         <div>
  //           <div
  //             onClick={(e) => {
  //               handleMobileSubmenu("mobile_profil");
  //             }}
  //             class="pointer hover-underline my-3 mx-4"
  //             style={{
  //               color: "#005F3B",
  //               fontSize: 18,
  //               fontFamily: "Roboto",
  //               fontWeight: "300",
  //             }}
  //           >
  //             Profil Perusahaan
  //           </div>


  //           <div class="mx-5 mobileSubmenuWrapper" id="mobile_profil">
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/sejarah-singkat");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Sejarah Singkat
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/visi-misi");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Visi dan Misi
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/bisnis-perusahaan");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Bisnis Perusahaan
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/tujuan-dan-budaya");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Tujuan dan Budaya
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/manajemen");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Manajemen
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/gcg");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               GCG
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/pkbl");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               PKBL
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/laporan");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Laporan
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/informasi-pemegang-saham");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Informasi Pemegang Saham
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/komite");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Komite
  //             </div>
  //             {/* <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/profil/rkap");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               RKAP
  //             </div> */}
  //           </div>
  //         </div>
  //         <div>
  //           {" "}
  //           <div
  //             onClick={(e) => {
  //               handleMobileSubmenu("mobile_produk");
  //             }}
  //             class="pointer hover-underline my-3 mx-4"
  //             style={{
  //               color: "#005F3B",
  //               fontSize: 18,
  //               fontFamily: "Roboto",
  //               fontWeight: "300",
  //             }}
  //           >
  //             Produk
  //           </div>
  //           <div class="mx-5 mobileSubmenuWrapper" id="mobile_produk">
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/properti");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Properti
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Logistik
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/plb");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Pusat Logistik Berikat
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/kbn-beton");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               KBN Beton
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/rsu");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               RSU & Pekerja
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/pusat-bisnis");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Pusat Bisnis
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/sbu-pengelolaan-air");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               SBU Pengelolaan Air
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/fasilitas-umum");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Fasilitas Umum
  //             </div>
  //           </div>
  //         </div>

  //         <div>
  //           {" "}
  //           <div
  //             onClick={(e) => {
  //               console.log("submenu");
  //               handleMobileNavigation("/katalog");
  //             }}
  //             class="pointer hover-underline my-3 mx-4"
  //             style={{
  //               color: "#005F3B",
  //               fontSize: 18,
  //               fontFamily: "Roboto",
  //               fontWeight: "300",
  //             }}
  //           >
  //             Katalog Produk
  //           </div>
  //           <div class="mx-5 mobileSubmenuWrapper">
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Properti
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Logistik
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Pusat Logistik Berikat
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Tujuan dan Budaya
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               GCG
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               PKBL
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Laporan
  //             </div>
  //           </div>
  //         </div>

  //         <div>
  //           {" "}
  //           <div
  //             onClick={(e) => {
  //               console.log("submenu");
  //               handleMobileSubmenu("mobile_media");
  //             }}
  //             class="pointer hover-underline my-3 mx-4"
  //             style={{
  //               color: "#005F3B",
  //               fontSize: 18,
  //               fontFamily: "Roboto",
  //               fontWeight: "300",
  //             }}
  //           >
  //             Media
  //           </div>
  //           <div class="mx-5 mobileSubmenuWrapper" id="mobile_media">
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/berita");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Berita
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/foto");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Logistik
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/video");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Video
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/majalah");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Majalah
  //             </div>
  //             {/* <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/kliping");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //              Kliping
  //             </div> */}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/pengumuman");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Pengumuman
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/penghargaan");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Penghargaan
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/rups");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Informasi RUPS
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/kinerja-keuangan");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Kinerja Keuangan
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/media/kalender-kegiatan");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Kalender Kegiatan
  //             </div>
  //           </div>
  //         </div>
  //         <div>
  //           {" "}
  //           <div
  //             onClick={(e) => {
  //               console.log("submenu");
  //               handleMobileNavigation("/media/pengumuman");
  //             }}
  //             class="pointer hover-underline my-3 mx-4"
  //             style={{
  //               color: "#005F3B",
  //               fontSize: 18,
  //               fontFamily: "Roboto",
  //               fontWeight: "300",
  //             }}
  //           >
  //             Pengadaan Barang dan Jasa
  //           </div>
  //           <div class="mx-5 mobileSubmenuWrapper">
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Properti
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Logistik
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Pusat Logistik Berikat
  //             </div>{" "}
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Tujuan dan Budaya
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               GCG
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               PKBL
  //             </div>
  //             <div
  //               onClick={(e) => {
  //                 handleMobileNavigation("/produk/logistik");
  //               }}
  //               class="pointer hover-underline navbarFont "
  //               style={{
  //                 color: "#005F3B",
  //                 fontSize: 18,
  //                 fontFamily: "Roboto",
  //                 fontWeight: "300",
  //               }}
  //             >
  //               Laporan
  //             </div>
  //           </div>
  //         </div>
  //         <div style={{ height: "100vh" }}></div>
  //       </div>
  //     </div>
  //   );
}
